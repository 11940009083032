<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            发票信息管理
        </div>
        <div class="vip-content-inner">
            <div class="login-register-form inline bill-form" v-if="edit">
                <el-form label-position="top" ref="billinfoForm" :model="billinfoForm" :rules="billinfoRules">
                    <el-form-item label="单位名称：" prop="companyName">
                        <div class="con">
                            <el-input v-model="billinfoForm.companyName" placeholder="单位名称"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="纳税人识别码：" prop="taxpayerIdentificationNumber">
                        <div class="con">
                            <el-input v-model="billinfoForm.taxpayerIdentificationNumber" placeholder="纳税人识别码"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册地址：" prop="registeredAddress">
                        <div class="con">
                            <el-input v-model="billinfoForm.registeredAddress" placeholder="注册地址"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="注册电话：">
                        <div class="con">
                            <el-input v-model="billinfoForm.registrationTelephone" placeholder="注册电话"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="开户银行：" prop="depositBank">
                        <div class="con">
                            <el-input v-model="billinfoForm.depositBank" placeholder="开户银行"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="银行账户：" prop="bankAccountNumber">
                        <div class="con">
                            <el-input v-model="billinfoForm.bankAccountNumber" placeholder="银行账户"></el-input>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="btn-row">
                    <div class="g-btn mid tac" @click="saveinfo()">
                        <img src="@/assets/img/i-save.png" alt="">
                        确认保存
                    </div>
                </div>
                <div class="tips-box">
                    <h3>温馨提示</h3>
                    <p>·注意有效增值税发票开票资质仅为一个；</p>
                    <p>·单位名称必须是您公司营业执照上的全称；</p>
                    <p>·纳税人识别号必须是您公司《税务登记证》的编号，-般为15位，请仔细核对后输入；</p>
                    <p>·注册地址必须是您公司营业执照上的注册地址；</p>
                    <p>·电话请提供能与您公司保持联系的有效电话；</p>
                    <p>·开户银行必须是您公司银行开户许可证上的开户银行；</p>
                    <p>·银行账号必须是您公司开户许可证上的银行账号。</p>
                    <p>·发票内容如需修改，请联系客服，请致电400-185-8866</p>
                </div>
            </div>
            <template v-else>
                <div class="address-list table" v-if="billinfoList.length>0">
                    <div class="item title">
                        <div class="radio">单位名称</div>
                        <div class="tools">操作</div>
                    </div>
                    <div class="item def" v-for="(item,index) in billinfoList" :key="index">
                        <div class="radio">
                            <p>{{ item.companyName }}</p>
                        </div>
                        <div class="tools">
                        <span class="blue"@click="clickedit(item)">
                            <img src="@/assets/img/edit-blue.png" alt="">
                            编辑</span>
                            <span @click="showDel = true,infoId=item.id" class="red">
                            <img src="@/assets/img/del.png" alt="">
                            删除</span>
                            <span class="gray" style="color: #ea6100" v-if="item.isDefault==1"><img src="@/assets/img/def-active.png" alt="">默认</span>
                            <span class="c-666 gray" @click="setdefault(item.id)" v-else><img src="@/assets/img/def.png" alt="">默认</span>
                        </div>
                    </div>
                </div>
                <div v-else class="paddingTB20px text_cen">暂未添加发票信息</div>
                <div style="display: flex;justify-content: flex-end;">
                    <div class="g-btn add-btn" @click="edit = true">
                        <img src="@/assets/img/i-bill.png" alt="">
                        添加发票信息
                    </div>
                </div>
            </template>


        </div>
        <delPop v-if="showDel" @close="showDel = false" @confirm="deleteInfo"/>
    </div>
</template>

<script>
import delPop from "@/components/delPop.vue";
import {
    billInfoList,
    billInfosave,
    billInfoupdate,
    billInfodelete,
    billInfodefault, billAddressDefault,
} from "@/api/account";

export default {
    name: "addressForBill",
    components: {delPop},
    data() {
        return {
            showDel: false,
            billinfoForm: {
                companyName:'',
                taxpayerIdentificationNumber:"",
                registeredAddress:'',
                registrationTelephone:'',
                depositBank:"",
                bankAccountNumber:""
            },
            billinfoRules: {
                companyName: [{required: true, trigger: 'blur', message: '必填项'}],
                taxpayerIdentificationNumber: [{required: true, trigger: 'blur', message: '必填项'}],
                registeredAddress: [{required: true, trigger: 'blur', message: '必填项'}],
                depositBank: [{required: true, trigger: 'blur', message: '必填项'}],
                bankAccountNumber: [{required: true, trigger: 'blur', message: '必填项'}],
            },
            edit:false,
            billinfoList:[], // 发票信息列表
            billinfoId:"",

        }
    },
    mounted(){
        // 有token并且未过期
        if(localStorage.getItem('Token') && !this.$utils.isTokenExpired()){
            // console.log(provice)
            this.getbillinfoList()
        }else{
            this.$router.push({path:'/home'})
        }
    },
    methods:{
        // 点击编辑
        clickedit(item){
            this.infoId=item.id
            this.billinfoForm= {
                companyName:item.companyName,
                taxpayerIdentificationNumber:item.taxpayerIdentificationNumber,
                registeredAddress:item.registeredAddress,
                registrationTelephone:item.registrationTelephone,
                depositBank:item.depositBank,
                bankAccountNumber:item.bankAccountNumber
            }
            this.edit = true
        },
        // 设置默认
        setdefault(id){
            console.log('设置默认')
            billInfodefault({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "操作成功",
                        duration: 2000
                    });
                    this.getbillinfoList() // 重新获取发票信息列表
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },
        //保存
        saveinfo(){
            if(this.infoId){ // 编辑
                this.addbillInfo(this.infoId)
            }else{ // 新增
                this.addbillInfo()
            }
        },
        // 清空表单
        clearForm(){
            this.edit = false
            this.billinfoForm={
                companyName:'',
                taxpayerIdentificationNumber:"",
                registeredAddress:'',
                registrationTelephone:'',
                depositBank:"",
                bankAccountNumber:""
            }
            this.infoId=''
        },


        //  -------------------------接口请求
        getbillinfoList(){ // 获取发票信息列表
            let customerId=localStorage.getItem('customId')
            billInfoList({customerId:customerId}).then((res) => {
                if (res.data.code==200) {
                    this.billinfoList=res.data.data
                }

            })
        },
        // 新增发票信息
        addbillInfo(id){
            this.$refs.billinfoForm.validate(valid => {
                if (valid) {
                    // if (!(/^1[3456789]\d{9}$/.test(this.billinfoForm.registrationTelephone))) {
                    //     this.$message({
                    //         type: 'warning',
                    //         message: '手机号格式不正确！',
                    //         duration: 1500
                    //     });
                    //     return
                    // }
                    let isDefault= this.billinfoList.length==0?1:0
                    let param={
                        customerId:localStorage.getItem('customId'), // 登录用户对应的客户的ID
                        companyName:this.billinfoForm.companyName,
                        taxpayerIdentificationNumber:this.billinfoForm.taxpayerIdentificationNumber,
                        registeredAddress:this.billinfoForm.registeredAddress,
                        registrationTelephone:this.billinfoForm.registrationTelephone,
                        depositBank:this.billinfoForm.depositBank,
                        bankAccountNumber:this.billinfoForm.bankAccountNumber,
                        isDefault:isDefault // 1是0否
                    }
                    let url=billInfosave
                    if(id){ // 编辑
                        param.id=id
                        url=billInfoupdate
                    }else{
                        url=billInfosave
                    }
                    url(param).then((res) => {
                        if (res.data.code==200) {
                            this.$message({
                                type: 'success',
                                message: "保存成功",
                                duration: 2000
                            });
                            this.getbillinfoList() // 重新获取地址列表
                            this.clearForm()
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 2000
                            });
                        }

                    })
                }
            })

        },
        deleteInfo(){
            console.log(this.infoId)
            billInfodelete({ids:this.infoId}).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "删除成功",
                        duration: 2000
                    });
                    this.getbillinfoList() // 重新获取地址列表
                    this.showDel=false
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
                this.infoId=''

            })
        },


    //    ---------校验 银行卡号
        checkBankno(bankno) {
            var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhm进行比较）
            var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
            var newArr = [];

            for (var i = first15Num.length - 1; i > -1; i--) {
                //前15或18位倒序存进数组
                newArr.push(first15Num.substr(i, 1));
            }

            var arrJiShu = []; //奇数位*2的积 <9
            var arrJiShu2 = []; //奇数位*2的积 >9
            var arrOuShu = []; //偶数位数组
            for (var j = 0; j < newArr.length; j++) {
                if ((j + 1) % 2 == 1) {
                    //奇数位
                    if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2);
                    else arrJiShu2.push(parseInt(newArr[j]) * 2);
                } //偶数位
                else arrOuShu.push(newArr[j]);
            }

            var jishu_child1 = []; //奇数位*2 >9 的分割之后的数组个位数
            var jishu_child2 = []; //奇数位*2 >9 的分割之后的数组十位数
            for (var h = 0; h < arrJiShu2.length; h++) {
                jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
                jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
            }

            var sumJiShu = 0; //奇数位*2 < 9 的数组之和
            var sumOuShu = 0; //偶数位数组之和
            var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
            var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
            var sumTotal = 0;
            for (var m = 0; m < arrJiShu.length; m++) {
                sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
            }
            for (var n = 0; n < arrOuShu.length; n++) {
                sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
            }
            for (var p = 0; p < jishu_child1.length; p++) {
                sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
                sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
            }
            //计算总和
            sumTotal =
                parseInt(sumJiShu) +
                parseInt(sumOuShu) +
                parseInt(sumJiShuChild1) +
                parseInt(sumJiShuChild2);
            //计算Luhm值
            var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
            var luhm = 10 - k;
            if (lastNum == luhm) {
                return true;
            } else {
                return false;
            }
        }

    },

}
</script>


<style scoped>

</style>
